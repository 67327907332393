import React, { useState, useEffect, } from "react"
import { graphql } from 'gatsby'
import SEO from "../components/seo"
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import StickyFooter from "../components/Footer/StickyFooter";
import NewsLetter from "../components/Home/NewsLetter/NewsLetter";
import Modules from '../components/home-modules'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/scss/bootstrap.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../scss/bootstrap-overrides.scss";
import "../scss/grunticon.scss";
import "../scss/global.scss";
import "../scss/custom.scss";


const HomePageTemplate = (props) => {
  const [renderComponent, setRenderComponent] = useState(true);
  const [state, setState] = React.useState({
    showMenu: false
  })

  const handlerClick = () => {
    setState({ ...state, showMenu: !state.showMenu })
  }

  const GQLPage = props.data.glstrapi?.article;
  const Page_Modules = props.data.glstrapi?.article?.Modules;
  const Site = props.data.glstrapi?.siteConfig;
  let custom_css = ''
  if (GQLPage.Custom_CSS_Classname) {
    custom_css = GQLPage.Custom_CSS_Classname

  }

  useEffect(() => {
    window.addEventListener("mousemove", () => {
      //scriptInsert()
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
    window.addEventListener("keypress", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
    window.addEventListener("touchmove", () => {
      //scriptInsert()
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })

    if (GQLPage.Pagename !== "Home") {
      setRenderComponent(true)
    }
  }, [])


  const scriptInsert = () => {
    if (document.getElementById("chat") === null) {
      var script = document.createElement('script');
      script.id = 'chat';
      script.src = '//code.jivosite.com/widget/fOl4Wvtle2';
      document.getElementsByTagName('head')[0].appendChild(script);
      var bugherdScript = document.createElement('script');
      bugherdScript.id = 'bugherd';
      bugherdScript.type = 'text/javascript';
      bugherdScript.src = 'https://www.bugherd.com/sidebarv2.js?apikey=nd3ndzjinzsmaspd9uhqaa';
      document.getElementsByTagName('body')[0].appendChild(bugherdScript);
    }
  }
  var metaTitle = GQLPage.Meta_Title;
  var metaDesc = GQLPage.Meta_Description;
  if (props.location.pathname.includes("property-news") && props.params['*'] == "living-in-dubai") {
    metaTitle = 'News and insights about living in Dubai';
    metaDesc = 'For the most up to date news and insightful analysis about living in Dubai, from our team of real estate professionals.';
  } else if (props.location.pathname.includes("property-news")) {
    var cat = props.params['*']
    if (cat) {
      cat = cat.replace('-', ' ');
    }
    metaTitle = `Property ${cat} news Dubai`;
    metaDesc = `Get all the latest real estate ${cat} news in Dubai right here at Dacha Real Estate. We cover everything from new property developments to market trends and more!`;
  }

  return (    
    <div className={custom_css}>
      <link rel="preload" as="image" href="https://ggfx-dachare.s3.eu-west-2.amazonaws.com/x.prod/700x800/home_banner_1_2f3e4e8221.webp"></link>
      <SEO title={metaTitle} description={metaDesc} location={props.location} />
      <div className={`${state.showMenu ? "open-search-block" : ""} ${GQLPage.Banner_Image && (GQLPage.Layout === 'Default' || GQLPage.Layout === 'Our_Story_Landing') ? 'homepage' : (GQLPage.Layout === 'Static' || GQLPage.Layout === 'News_Landing') ? 'static-page' : ''}`}>
        <Header homeArticleId={props.pageContext} location={props.location} showMenu={state.showMenu} handlerClick={handlerClick} fixed={true} />
        <Modules site={Site} page={GQLPage} modules={Page_Modules} {...props} />
        {renderComponent &&
          <>
            <NewsLetter />
            <Footer popularSearch={GQLPage.Popular_Search} />
            {(GQLPage._id === "60c09aa4abdf081f435b6450") &&
              <StickyFooter />
            }

          </>
        }
      </div>
    </div>
  )
}

export default HomePageTemplate

export const pageQuery = graphql`
  query homePageQuery($id: ID!) {
      glstrapi {
        article(id: $id, publicationState: LIVE) {
          _id
          Pagename
          Video_URL
          Mobile_Video
          Show_Search_Type
          Show_Google_Rating
          Meta_Title
          Meta_Description
          Layout
          Header_Content
          Header_Contact_Info
          Header_CTA_2_Label
          Header_CTA_1_Label
          Custom_CSS_Classname
          Useful_Information
          Header_Style
          imagetransforms
          Popular_Search
          Header_CTA_1_Link {
            id
          }
          Header_CTA_2_Link {
            id
          }
          Banner_Image {
            url
            alternativeText
          }

          Modules {
            __typename

            ... on GLSTRAPI_ComponentModuleFeaturedProperties {
              Place
              Search_Type
              id
            }
            ... on GLSTRAPI_ComponentModuleAddBlock {
              id
              Add_Service {
                CTA_Label
                Content
                Image {
                  alternativeText
                  url
                  formats
                }
                CTA_Link {
                  id
                }
              }
            }
            ... on GLSTRAPI_ComponentModuleGoogleReviews {
              id
              Reviews_Background_Image {
                alternativeText
                url
              }
            }
            ... on GLSTRAPI_ComponentModuleStats {
              id
              Add_Stat {
                Content
                Value
              }
            }
            ... on GLSTRAPI_ComponentModuleGlobalModules {
              id
              Select
            }
            ... on GLSTRAPI_ComponentModuleServiceCards {
              id
              Service_Cards {
                Description
                Image{
                  url
                }
                Title
                id
              }
            }


            ... on GLSTRAPI_ComponentModuleFooterBlock {
              id
              type: __typename
              FooterBlockTitle: Title
              CTA_1_Label
              CTA_1_Action
              CTA_1_Link {
                id
                Link_Type
              }
              CTA_2_Label
              CTA_2_Action
              CTA_2_Link {
                id
                Link_Type
              }
            }
          }
          
          all_menus {
            Label
            Main_Parent {
              Label
              id
              URL
            }
            Sub_Parent {
              Label
              URL
              id
            }
          }
        }

        siteConfig {
          Address
          Email
          Phone
          Available_Timings
        }

      }
  }
`